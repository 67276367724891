<template>
  <div>
    <nav id="topbar" class="mobile customer-theme">
      <div class="topbar-button">
        <button type="button" id="toggle-sidebar" class="btn customer-theme"
          @click="toggleSidebar()" :class="{active: sideBarActive}"></button>
      </div>
      <div class="container-fluid">
        <div class="text-container">
          <router-link to="/">
            <img src="../assets/logo-all-in-law-174x26.png" alt="" /></router-link>
        </div>
      </div>
    </nav>
    <nav id="sidebar" :class="{active: sideBarActive}">
      <div class="sidebar-content">
        <div class="sidebar-title desktop">
          <router-link to="/">
            <img src="../assets/logo-all-in-law-174x26.png" alt="" /></router-link>
        </div>
        <div class="admin-standard-btn-1">
          <router-link :to="{name: 'logout'}" class="btn customer-theme">
          <span class="fa fa-sign-out"></span> Déconnexion</router-link>
        </div>
        <ul class="list-unstyled customer-theme">
          <li :class="{active: ($route.name === 'events')}">
            <router-link to="/"><span class="fa fa-calendar"></span> Vos rendez-vous</router-link>
          </li>
        </ul>
        <ul class="list-unstyled customer-theme stick-to-bottom">
          <!--
          <li><a :href="helpUri">
            <span class="fa fa-question-circle"></span> Centre&nbsp;d'aide</a>
          </li>
          -->
          <li :class="{active: ($route.name === 'settings')}">
            <router-link :to="{ name: 'settings' }">
              <span class="fa fa-cog"></span> Configuration
            </router-link>
            <div class="watermark desktop">
              <span>Powered by</span><br />
              <img src="../assets/logo-lexdev-light-grey-56x20.png" alt="" />
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <div id="content">
      <router-view/>
      <footer>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="watermark">
                <span>Powered by</span><br>
                <img src="../assets/logo-lexdev-dark-grey-56x20.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'layout',
  data() {
    return {
      sideBarActive: false,
      helpUri: process.env.VUE_APP_HELP_BASE_URI,
    };
  },
  methods: {
    toggleSidebar() {
      this.sideBarActive = !this.sideBarActive;
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.sideBarActive = false;
    next();
  },
};
</script>
