<template>
  <div class="container">
    <h1 id="path-header"><span id="path-header-current">Vos rendez-vous</span></h1>
    <p class="path-help-description">
      Retrouvez ci-dessous la liste de vos rendez-vous pour la relecture de documents (
      <span class="fa fa-video-camera"></span>&nbsp;: rendez-vous visio &ndash; <span
      class="fa fa-phone"></span>&nbsp;: rendez-vous téléphonique).</p>
    <div class="events-main-wrapper">

      <div class="col-md-12">
        <div class="events-period">
          <router-link :to="{ name: 'events', query: { status: 'future' } }"
            class="events-period-future"
            :class="{ active: status === 'future' }">
            À venir
          </router-link>
          <div class="events-period-switch">
            <label class="period-switch-wrapper">
              <input @change="toggleStatus" id="period-switch"
                type="checkbox" :checked="status === 'past'" />
              <span class="slider"></span>
            </label>
          </div>
          <router-link :to="{ name: 'events', query: { status: 'past', direction: 'desc' } }"
            class="events-period-past" :class="{ active: status === 'past' }">
            Passés
            </router-link>
        </div>
      </div>
      <div v-if="!loading && events.length > 0">
        <div class="row row-eq-height event-header">
          <div class="col-xl-2 col-md-2">Date</div>
          <div class="col-xl-1 col-md-1">Heure</div>
          <div class="col-xl-3 col-md-3">Client</div>
          <div class="col-xl-6 col-md-6">Document</div>
        </div>
        <div v-for="event in events" :key="event.id" class="row event-row"
        :class="{canceled: event.status === 'canceled'}">
          <div class="col-xl-2 col-md-2 col-6 event-date-wrapper">
            <div class="event-date">{{event.start_time|dateTimeFormat('D MMMM YYYY')}}</div>
          </div>
          <div class="col-xl-1 col-md-1 col-6 event-time-wrapper">
            <div class="event-time">{{event.start_time|dateTimeFormat('HH[h]mm')}}</div>
          </div>
          <div class="col-xl-3 col-md-3 col-12 event-customer-wrapper">
            <div class="event-customer">{{event.user.full_name}}</div>
          </div>
          <div class="col-xl-4 col-md-4 col-12 event-document-wrapper">
            <div class="event-document-name text-truncate">
              <span class="name" v-if="event.document">{{event.document.name}}</span>
              <span class="name deleted" v-else><span
              class="fa fa-ban"></span> Pas de document</span>
              <span class="canceled" v-if="event.status === 'canceled'"> (Annulé)</span>
            </div>
          </div>
          <div class="col-xl-2 col-md-2 col-12 event-document-action-bar">
            <div class="event-document-action-bar-element"
            :class="{unavailable: !event.document}">
              <router-link v-if="event.document"
                :to="{ name: 'download', params: { document: event.document.id } }">
              </router-link>
              <span class="fa fa-download"></span>
            </div>
            <div class="event-document-action-bar-element">
              <router-link :to="{ name: 'event', params: { event: event.id } }">
              </router-link>
              <span v-if="event.info.type === 'zoom'" class="fa fa-video-camera"></span>
              <span v-if="event.info.type === 'teams'" class="fa fa-video-camera"></span>
              <span v-if="event.info.type === 'google'" class="fa fa-video-camera"></span>
              <span v-if="event.info.type === 'phone'" class="fa fa-phone"></span>
            </div>
          </div>
        </div>
        <div class="navigation-wrapper">
          <div class="row row-eq-height">
            <div class="col-md-12 page-navigation-wrapper">
              <nav v-if="totalPages > 1" aria-label="Page navigation" class="page-navigation">
                <ul class="pagination">
                  <li class="page-item"
                    :class="{disabled: currentPage == 1}">
                    <router-link :to="{ name: 'events', query: queryPage(currentPage-1) }"
                      class="page-link">
                      <span class="fa fa-arrow-left"></span>
                    </router-link>
                  </li>
                  <li v-for="pageNumber in actualPages"
                    :key="`paginator-${pageNumber}`"
                    class="page-item"
                    :class="{
                      active: pageNumber === currentPage,
                      continued: pageNumber.toString().substr(0,2) === 'el'
                    }">
                    <router-link :to="{ name: 'events', query: queryPage(pageNumber) }"
                      v-if="pageNumber.toString().substr(0,2) !== 'el'"
                      class="page-link">
                      {{pageNumber}}
                    </router-link>
                    <a v-else class="page-link">...</a>
                  </li>
                  <li class="page-item"
                    :class="{disabled: currentPage == totalPages}">
                    <router-link :to="{ name: 'events', query: queryPage(currentPage+1) }"
                      class="page-link">
                      <span class="fa fa-arrow-right"></span>
                    </router-link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loading && events.length === 0" class="no-event">
      Vous n'avez aucun rendez-vous à venir
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import eventsAPI from '@/api/events';

export default {
  name: 'events',
  data() {
    return {
      events: [],
      currentPage: 1,
      perPage: 15,
      totalPages: 1,
      sort: 'date',
      direction: 'asc',
      search: '',
      status: null,
      loading: true,
    };
  },
  computed: {
    ...mapGetters('auth', [
      'currentUser',
    ]),
    actualPages() {
      if (this.totalPages <= 10) {
        return this.totalPages;
      }
      if (this.currentPage < 5) {
        const pages = [];
        for (let i = 1; i < 8; i += 1) {
          pages.push(i);
        }
        return [...pages, 'el2', this.totalPages];
      }
      if (this.currentPage > (this.totalPages - 5)) {
        const pages = [];
        for (let i = this.totalPages - 6; i <= this.totalPages; i += 1) {
          pages.push(i);
        }
        return [1, 'el1', ...pages];
      }
      const pages = [];
      for (let i = this.currentPage - 2; i <= this.currentPage + 2; i += 1) {
        pages.push(i);
      }
      return [1, 'el1', ...pages, 'el2', this.totalPages];
    },
  },
  methods: {
    loadEvents() {
      this.loading = true;
      const params = {
        direction: this.status === 'past' ? 'desc' : 'asc',
        page: this.currentPage,
        per_page: this.perPage,
        sort: this.sort,
        search: this.search,
        status: this.status,
      };
      eventsAPI.list(params).then((response) => {
        this.events = response.data;
        this.currentPage = response.meta.current_page;
        this.perPage = response.meta.per_page;
        this.totalPages = response.meta.last_page;
        this.loading = false;
      }).catch(() => {
        this.$store.commit('auth/unsetToken');
        this.$router.push({ name: 'login' });
      });
    },
    checkQuery(query) {
      this.status = query.status || 'future';
      this.currentPage = query.page || 1;
      this.search = query.search || '';
      this.sort = query.sort || 'date';
      this.direction = query.direction || 'asc';
    },
    queryPage(page) {
      return {
        status: this.status,
        search: this.search,
        sort: this.sort,
        direction: this.direction,
        page,
      };
    },
    toggleStatus() {
      if (this.status === 'past') {
        this.$router.push({ name: 'events', query: { status: 'future' } });
      } else {
        this.$router.push({ name: 'events', query: { status: 'past' } });
      }
    },
  },
  mounted() {
    this.checkQuery(this.$route.query);
    this.loadEvents();
  },
  beforeRouteUpdate(to, from, next) {
    this.loading = true;
    this.checkQuery(to.query);
    this.loadEvents();
    next();
  },
};
</script>

<style scoped>

</style>
