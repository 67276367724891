<template>
  <div class="container event-details-wrapper">
    <h1 id="path-header">
        Vos rendez-vous › <span id="path-header-current">
          {{event.start_time|dateTimeFormat('D MMMM YYYY à HH[h]mm')}}
          avec {{event.user.full_name}}</span>
    </h1>
    <p class="path-help-description">
      Retrouvez ci-dessous les détails de votre rendez-vous.</p>
    <div v-if="event" class="col-md-12 event-details">
        <p>Votre Legal Call avec
            <strong><template v-if="event.user.gender==='female'">M<sup>me</sup></template>
            <template v-else>M.</template> {{event.user.full_name}}</strong>
            est programmé le
            <strong>{{event.start_time|dateTimeFormat('D MMMM YYYY à HH[h]mm')}}</strong>.</p>
        <p v-if="event.status === 'canceled'">Annulé</p>
        <!-- Zoom -->
        <template v-if="event.info.type === 'zoom'">
          <p>Cet échange, prévu pour durer <strong>{{event.duration}} minutes</strong>,
            aura lieu par <strong>visioconférence</strong>&nbsp;:<br />
            <a :href="event.info.zoom_url" target="_blank">
              Cliquez pour rejoindre la conférence Zoom</a></p>
          <p><strong>Mot de passe</strong>&nbsp;:<br />{{event.info.zoom_password}}</p>
        </template>
        <!-- Teams -->
        <template v-if="event.info.type === 'teams'">
          <p>Cet échange, prévu pour durer <strong>{{event.duration}} minutes</strong>,
            aura lieu par <strong>visioconférence</strong>&nbsp;:<br />
            <a :href="event.info.teams_url" target="_blank">
              Cliquez pour rejoindre la conférence Teams</a></p>
        </template>
        <!-- Google -->
        <template v-if="event.info.type === 'google'">
          <p>Cet échange, prévu pour durer <strong>{{event.duration}} minutes</strong>,
            aura lieu par <strong>visioconférence</strong>&nbsp;:<br />
            <a :href="event.info.google_url" target="_blank">
              Cliquez pour rejoindre la conférence Google</a></p>
        </template>
        <!-- Phone -->
        <template v-if="event.info.type === 'phone'">
          <p>Cet échange, prévu pour durer <strong>{{event.duration}} minutes</strong>,
            aura lieu par <strong>téléphone</strong>&nbsp;:<br />
            <a :href="`tel:${strip_spaces(event.info.phone_number)}`">
              {{strip_spaces(event.info.phone_number)}}</a></p>
        </template>
        <p v-if="event.info.message"><strong>Message</strong>&nbsp;:</p>
        <div v-if="event.info.message" class="message-text">{{event.info.message}}</div>
    </div>
    <h2><a @click.prevent="$router.go(-1)" href="">
      <span class="fa fa-arrow-left" aria-hidden="true"></span> Retour</a></h2>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import eventsAPI from '@/api/events';

export default {
  name: 'event-details',
  data() {
    return {
      event: null,
      loading: true,
    };
  },
  computed: {
    ...mapGetters('auth', [
      'currentUser',
    ]),
  },
  methods: {
    loadEvent() {
      this.loading = true;
      eventsAPI.get(this.$route.params.event).then((response) => {
        this.event = response.data;
        this.loading = false;
      }).catch(() => {
        this.$store.commit('auth/unsetToken');
        this.$router.push({ name: 'login' });
      });
    },
    strip_spaces(number) {
      return number.replace(/\s/g, '');
    },
  },
  mounted() {
    this.loadEvent();
  },
  beforeRouteUpdate(to, from, next) {
    this.loading = true;
    this.loadEvent();
    next();
  },
};
</script>

<style scoped>

</style>
