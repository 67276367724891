import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import eventsAPI from '@/api/events';
import EventDetails from '@/views/EventDetails.vue';
import Events from '@/views/Events.vue';
import Layout from '@/views/Layout.vue';
import Login from '@/views/Login.vue';
import Settings from '@/views/Settings.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '',
        name: 'events',
        component: Events,
        meta: { auth: true, title: 'All in Law - Vos rendez-vous' },
      },
      {
        path: '/rendez-vous/:event',
        name: 'event',
        component: EventDetails,
        meta: { auth: true, title: 'All in Law - Votre rendez-vous' },
      },
      {
        path: '/configuration',
        name: 'settings',
        component: Settings,
        meta: { auth: true, title: 'All in Law - Configuration' },
      },
    ],
  },
  {
    path: '/connexion',
    name: 'login',
    component: Login,
    meta: { guest: true, title: 'All in Law' },
  },
  {
    path: '/telecharger/:document',
    name: 'download',
    meta: { download: true },
  },
  {
    path: '/deconnexion',
    name: 'logout',
    meta: { logout: true },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.auth)) {
    if (!store.getters['auth/loggedIn']) {
      next({
        name: 'login',
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters['auth/loggedIn']) {
      next({ name: 'events' });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.logout)) {
    store.commit('auth/unsetToken');
    next({ name: 'login' });
  } else if (to.matched.some((record) => record.meta.download)) {
    eventsAPI.download(to.params.document).then((data) => {
      next(false);
      window.location.replace(`${process.env.VUE_APP_API_BASE_URI}/documents/download/${data.key}`);
    });
  } else {
    next();
  }
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || 'LexDev';
  });
});

export default router;
