import jwtDecode from 'jwt-decode';

const STORAGE_KEY = `LXDV${process.env.VUE_APP_API_SITE_KEY}`;

const decodeToken = (token) => {
  try {
    const obj = jwtDecode(token);
    return {
      id: obj.sub,
      firstName: obj.first_name,
      role: obj.role,
    };
  } catch (_) {
    return {
      id: null,
      firstName: '',
      role: null,
    };
  }
};

const state = () => ({
  token: localStorage.getItem(STORAGE_KEY),
});

const getters = {
  currentUser: (state) => decodeToken(state.token),
  loggedIn: (state) => !!state.token,
};

const mutations = {
  setToken: (state, token) => {
    localStorage.setItem(STORAGE_KEY, token);
    state.token = token;
  },
  unsetToken: (state) => {
    localStorage.removeItem(STORAGE_KEY);
    state.token = null;
  },
};

const actions = {
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
