import http from '@/http';
import store from '@/store';

export default {
  list(params) {
    return new Promise((resolve, reject) => {
      http.get(`users/${store.getters['auth/currentUser'].id}/events`, { params }).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  download(documentId, type) {
    return new Promise((resolve, reject) => {
      http.post(`documents/${documentId}/download`, {
        type,
      }).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  get(id) {
    return new Promise((resolve, reject) => {
      http.get(`users/${store.getters['auth/currentUser'].id}/events/${id}`).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
  },
};
