import http from '@/http';

export default {
  update(user) {
    return new Promise((resolve, reject) => {
      http.patch(`users/${user.id}`, user).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
  },
};
