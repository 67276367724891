import Vue from 'vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import App from '@/App.vue';
import http from '@/http';
import router from '@/router';
import store from '@/store';

const moment = require('moment');

Vue.config.productionTip = false;
Vue.prototype.$http = http;

moment.locale('fr-FR');
Vue.filter('dateTimeFormat', (value, format) => moment(String(value)).format(format));

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
