import http from '@/http';
import store from '@/store';

http.interceptors.request.use((config) => {
  const newConfig = config;
  if (store.getters['auth/loggedIn']) {
    newConfig.headers.common.Authorization = `Bearer ${store.state.auth.token}`;
  }
  if (newConfig.method === 'patch') {
    newConfig.headers.common['Content-Type'] = 'application/merge-patch+json';
  }
  return newConfig;
}, (error) => Promise.reject(error));

http.interceptors.response.use((response) => response, (error) => {
  const originalRequest = error.config;
  if (error.response
    && error.response.status === 401
    && error.response.data.error === 'token_expired'
    && !originalRequest.isRetry) {
    originalRequest.isRetry = true;
    return http.post('auth/refresh-token').then((response) => {
      store.commit('auth/setToken', response.data.token);
      originalRequest.headers.Authorization = `Bearer ${store.state.auth.token}`;
      return http.request(originalRequest);
    }).catch((err) => Promise.reject(err));
  }
  return Promise.reject(error);
});

export default {
  login(email, password) {
    return new Promise((resolve, reject) => {
      http.post('auth/login', {
        email,
        password,
        site: process.env.VUE_APP_API_SITE_KEY,
      }).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  resetPassword(params) {
    return new Promise((resolve, reject) => {
      http.post('auth/reset-password', params).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  refreshToken() {
    return new Promise((resolve, reject) => {
      http.post('auth/refresh-token').then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
  },
};
