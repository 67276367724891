import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/store/auth';

Vue.use(Vuex);

const debugMode = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  strict: debugMode,
  modules: {
    auth,
  },
});
