<template>
  <div class="container-flex lxdv-login-form-wrapper">
    <div class="lxdv-login-form-decoration-1">
      <img src="../assets/login-bg-top-right-694x474.png" alt="">
    </div>
    <div class="lxdv-login-form-decoration-2">
      <img src="../assets/login-bg-bottom-left-525x125.png" alt="">
    </div>
    <div class="lxdv-login-form-decoration-3">
      <img src="../assets/login-bg-left-481x575.png" alt="">
    </div>
    <div class="lxdv-login-form-decoration-4">
      <img src="../assets/logo-lexdev-light-grey-56x20.png" alt="LexDev">
    </div>
    <div class="lxdv-login-form">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-8 mx-auto">
          <h3>Connexion<br />Legal Call</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-8 mx-auto">
          <div v-if="error" class="alert alert-danger alert-dismissible mx-auto">
            {{errorMessage}}
            <button type="button" class="btn-close" @click="error=false"></button>
          </div>
        </div>
      </div>
      <div class="row text-center">
        <form @submit.prevent="login" novalidate
          class="col-lg-4 col-md-6 col-sm-8 mx-auto login-form">
          <div class="form-group">
            <label for="email" class="form-label">Adresse e-mail</label>
            <input type="email" v-model="email" id="email" name="email"
              placeholder="Votre adresse e-mail" class="form-control">
          </div>
          <div class="form-group">
            <label for="password" class="form-label">Mot de passe</label>
            <input type="password" v-model="password" id="password"
              placeholder="Votre mot de passe" name="password" class="form-control">
          </div>
          <div class="form-group admin-standard-btn-1">
            <button type="submit" class="btn customer-theme">
              <span class="fa fa-sign-in"></span> Connexion</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import authAPI from '@/api/auth';

export default {
  name: 'login',
  data() {
    return {
      email: '',
      password: '',
      error: false,
      errorMessage: '',
      pending: false,
    };
  },
  methods: {
    login() {
      this.error = false;
      authAPI.login(this.email, this.password).then((data) => {
        this.$store.commit('auth/setToken', data.token);
        this.$router.replace(this.$route.query.redirect || '/');
      }).catch((error) => {
        if (!error.response) {
          this.errorMessage = 'Connexion impossible. Merci de réessayer ultérieurement.';
        } else {
          this.errorMessage = 'Adresse e-mail ou mot de passe incorrects.';
        }
        this.error = true;
      });
    },
  },
};
</script>

<style scoped>
.lxdv-login-form-wrapper {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: url('../assets/login-bg-clouds-625x625.png');
}

.lxdv-login-form-decoration-1 {
  position: absolute;
  top:0;
  right:0;
  z-index: 1;
}

.lxdv-login-form-decoration-1 img {
  height: auto;
  width: 694px;
  max-width: 50vw;
}

.lxdv-login-form-decoration-2 {
  position: absolute;
  bottom:0;
  left:0;
  z-index: 0;
}

.lxdv-login-form-decoration-2 img {
  height: auto;
  width: 525px;
  max-width: 50vw;
}

.lxdv-login-form-decoration-3 {
  position: absolute;
  margin: auto 0;
  left: 5%;
  width: 25vw;
  height: auto;
  padding-bottom:80px;
  z-index: 2;
}

.lxdv-login-form-decoration-3 img {
  height: auto;
  max-width: 481px;
  width: 25vw;
}

.lxdv-login-form-decoration-4 {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 0;
}

.lxdv-login-form-decoration-4 img {
  height: auto;
  width: 56px;
}

.lxdv-login-form {
  padding-top: 20px;
  padding-bottom: 40px;
  padding-left: 15px;
  padding-right: 15px;
  z-index: 500;
}

.lxdv-login-form h3 {
  margin-top: 0;
  margin-bottom: 40px;
  text-align: center;
  font-variant: small-caps;
  font-weight: 400;
}

.lxdv-login-form form .form-group label {
  color: #8093ab;
}

.lxdv-login-form form input[type="email"], .lxdv-login-form form input[type="password"] {
  border: 1px solid #adbed4;
  font-size: 16px;
}

.lxdv-login-form form input[type="email"]:focus,
.lxdv-login-form form input[type="email"]:active,
.lxdv-login-form form input[type="email"]:active:focus,
.lxdv-login-form form input[type="password"]:focus,
.lxdv-login-form form input[type="password"]:active,
.lxdv-login-form form input[type="password"]:active:focus {
  border: 1px solid rgb(247, 113, 69);
}

.lxdv-login-form form input[type="email"]::-webkit-input-placeholder {
  color: #8093ab;
}

.lxdv-login-form form input[type="email"]::-moz-placeholder {
  color: #8093ab;
}

.lxdv-login-form form input[type="password"]::-webkit-input-placeholder {
  color: #8093ab;
}

.lxdv-login-form form input[type="password"]::-moz-placeholder {
  color: #8093ab;
}

.login-form .admin-standard-btn-1 .btn {
  font-size: 16px;
}

.password-reset-link {
  margin-top: 20px;
  text-align: center;
}

.password-reset-link a:link, .password-reset-link a:visited {
  font-size: 14px;
  text-decoration: none;
  color: rgb(247, 113, 69);
}

.password-reset-link a:hover {
  text-decoration: none;
  color: #8093ab;
}

@media (max-width: 991px) {
  .lxdv-login-form-decoration-3 {
    display: none;
  }
}

@media (max-width: 991px) and (min-height: 768px) {
  .lxdv-login-form-decoration-3 {
    display: inline;
    margin: 0;
    top: 5%;
    left: 5%;
    width: 25vw;
    height: auto;
  }
}

@media (max-width: 767px) {
  .lxdv-login-form-decoration-3 {
    display: none;
  }
}

@media (max-width: 767px) and (min-height: 768px) {
  .lxdv-login-form-decoration-3 {
    display: inline;
    margin: 0;
    top: 3%;
    left: 3%;
  }
}

@media (max-width: 575px) {
  .lxdv-login-form-decoration-3 {
    display: none;
  }
}
</style>
