<template>
  <div class="container settings">
    <h1 id="path-header"><span id="path-header-current">Configuration</span></h1>

    <form @submit.prevent>

      <div class="alert alert-success alert-dismissible"
        role="alert" v-if="updated" @click="updated=false">
        Modifications effectuées
        <button type="button" class="btn-close" aria-label="Close">
          <span aria-hidden="true"></span>
        </button>
      </div>

      <div class="row row-eq-height">
        <div class="form-group has-feedback col-xl-4 col-md-6">
          <div class="row">
            <label for="newpass" class="control-label">Nouveau mot de passe</label>
            <input v-model="user.password" type="password" class="form-control"
              :class="{'is-invalid': errors['password'] ? errors['password'][0] : false}"
              id="newpass" name="newpass" placeholder="Votre nouveau mot de passe" value=""
              aria-describedby="newpassErrorStatus" />
            <div class="invalid-feedback">{{errors['password'] ? errors['password'][0] : ''}}</div>
            <label for="newpass2" class="control-label">Confirmation du nouveau mot de passe</label>
            <input v-model="user.password_confirmation" type="password" class="form-control"
              id="newpass2" name="newpass2" placeholder="Confirmation du nouveau mot de passe"
              value="" aria-describedby="newpass2ErrorStatus" />
          </div>
        </div>
        <div class="form-group has-feedback col-xl-4 col-md-6 col-prevent-padding-on-xs
          comment-wrapper">

          <div class="row">
            <div class="comment">

              <div>
                <div>
                  <div>Si vous souhaitez changer votre mot de passe, inscrivez-le dans le champ
                    &laquo;&nbsp;nouveau mot de passe&nbsp;&raquo;, puis confirmez-le dans le
                    champ &laquo;&nbsp;confirmation du nouveau mot de passe&nbsp;&raquo;.</div>
                </div>
              </div>

            </div>
          </div>

        </div>
        <div class="col-xl-4 col-md-6"></div>
      </div>

      <div class="row">
        <div class="form-group has-feedback col-xl-4 col-md-6">
        </div>
        <div class="col-xl-4 col-md-6">
          <div class="admin-standard-btn-1"><a @click="updateUser" href="javascript:void(0);"
            class="btn customer-theme"><span class="fa fa-edit"></span> Modifier</a></div>
        </div>
        <div class="col-xl-4 col-md-0"></div>
      </div>

    </form>
  </div>
</template>

<script>
import usersAPI from '@/api/users';

export default {
  name: 'settings',
  data() {
    return {
      errors: [],
      updated: false,
      user: {
        id: this.$store.getters['auth/currentUser'].id,
      },
    };
  },
  methods: {
    updateUser() {
      this.errors = [];
      this.updated = false;
      usersAPI.update(this.user).then((user) => {
        this.user = user.data;
        this.updated = true;
      }).catch((e) => {
        if (e.response) {
          if (e.response.status === 422) {
            this.errors = e.response.data.errors;
          }
        } else {
          this.$store.commit('auth/unsetToken');
          this.$router.push({ name: 'login' });
        }
      });
    },
  },
};
</script>

<style scoped>

</style>
